const DetermineIndexing = (pageIndexingRequested: boolean, env: Record<string, string>, preview: boolean) => {
	// Always noindex, nofollow if page is a preview
	if (preview) {
		return false;
	}

	return pageIndexingRequested && env?.ENVIRONMENT_NAME === 'prod';
};

export default DetermineIndexing;
